
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { Negotiation } from '@/entities/purchase'
  import { fixPrice, isValidNumber } from '@genio/automotive-console/src/utils/general'

@Component({
  methods: { fixPrice },
})
  export default class NegotiationExecutive extends Vue {
  @Prop({ type: [String, Number, Object, Array] }) value!: any
  displayConsignment = false

  // Methods
  async mounted () {
    const process = (await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: {
        table_name: { _eq: 'purchase_order' },
      },
    }))[0]

    this.displayConsignment = process?.config?.display_consignment
  }

  // Getters
  get negotiation (): Negotiation {
    const { value } = this
    return value as Negotiation
  }

  get agreementInspectionPurchase () {
    const { inspection } = this.negotiation

    return inspection.appraisal.agreementAmountPreInspection
  }

  get discountPostInspection () {
    const { inspectionAmount } = this.negotiation
    return inspectionAmount.value
  }

  get discountPostInspectionAuthorized () {
    const { authorizePostInspection } = this.negotiation
    return authorizePostInspection
  }

  get discountPostInspectionAuthorizedConsignment () {
    const { authorizePostInspectionConsignment } = this.negotiation
    return authorizePostInspectionConsignment
  }

  get negotiationAuthorized () {
    const { negotiationResponseAmount } = this.negotiation
    return negotiationResponseAmount.value
  }

  get negotiationAuthorizedConsignment () {
    const { negotiationResponseAmountConsignment } = this.negotiation

    return negotiationResponseAmountConsignment.value
  }

  get displayNegotiationAuthorizedConsignmentDisplay () {
    const { negotiationAuthorizedConsignment, discountPostInspectionAuthorizedConsignment } = this

    return isValidNumber(negotiationAuthorizedConsignment) && isValidNumber(discountPostInspectionAuthorizedConsignment)
  }

  get offerPostInspection () {
    const { offerPostInspection } = this.negotiation
    return offerPostInspection
  }
  }
